import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Newsletter from "../components/section-newsletter"
import BackgroundInterior from "../components/background/interior"

const TermsOfServicePage = () => (
  <Layout>
    <SEO
      title="Terms of Service"
      description={`
        Welcome to Microincome.com (the "Website" or "Site"), a financial
        resource and information site. These Website Standard Terms And
        Conditions Of Use (these “Terms” or these “Website Standard Terms And
        Conditions”) contained herein on this webpage, shall govern your use
        of this website, including all pages within this website (collectively
        referred to herein below as this “Website”).
      `}
    />
    <BackgroundInterior>
      <h1 className="text-center">Terms of Service</h1>
      <Container theme="light" rounded>
        <h2>Introduction</h2>
        <p>
          Welcome to Microincome.com (the "Website" or "Site"), a financial
          resource and information site. These Website Standard Terms And
          Conditions Of Use (these “Terms” or these “Website Standard Terms And
          Conditions”) contained herein on this webpage, shall govern your use
          of this website, including all pages within this website (collectively
          referred to herein below as this “Website”). These Terms apply in full
          force and effect to your use of this Website and by using this
          Website, you expressly accept all terms and conditions contained
          herein in full. You must not use this Website, if you have any
          objection to any of these Website Standard Terms And Conditions. These
          Terms, together with our Privacy Policy, govern Microincome.com's
          relationship with you in relation to this Website.
        </p>
        <p>
          This Website is not for use by any minors (defined as those who are
          not at least 18 years of age), and you must not use this Website if
          you are a minor.
        </p>
        <p>
          The term "microincome.com" or '"us" or "we" refers to the owner of the
          Website. The term 'you' and "your" and "User" refers to the user or
          viewer of our website.
        </p>
        <p>
          Services related to or offered on the Site (hereinafter, the
          "Services") may include, without limitation, tools, applications,
          email services, bulletin and message boards, chat areas, news groups,
          forums, communities, calendars, and downloadable mobile applications
          related to the Site or provided through the Site. These Terms govern
          your use of the Site and Services that are provided. Please read this
          Agreement carefully before accessing or using the Site or Services.
          Each time you access or use the Site or Services, you agree to be
          bound by these Terms. If you do not agree to be bound by all of these
          Terms, you may not access or use the Site or Services.
        </p>
        <h2>Restrictions</h2>
        <p>
          You are expressly and emphatically restricted from all of the
          following:
        </p>
        <ul>
          <li>publishing any Website material in any media;</li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is, or may be, damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in a way that causes, or may cause, harm to the Website, or to any
            person or business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website, or while using
            this Website;
          </li>
          <li>using this Website to engage in any advertising or marketing.</li>
        </ul>
        <h2>Privacy, Children's Privacy, and Minor Visitors</h2>
        <p>
          Your use of this Site is subject to our Privacy Policy found elsewhere
          on this Site. Please review this policy, which also governs the Site
          and informs users of our data collection practices and how we use your
          personally identifiable information.
        </p>
        <p>
          We are committed to protecting the privacy of children. You should be
          aware that this Site is not intended or designed to attract children
          under the age of 13. We do not knowingly collect, either online or
          offline, personal information from persons under the age of thirteen.
          If you are under the age of 18, you may use this Site only with
          permission of a parent or guardian.
        </p>
        <h2>Grant of License</h2>
        <p>
          You are granted a personal, non-exclusive, non-transferable, revocable
          and limited license to access and use this Site strictly in accordance
          with these Terms. As a condition of your use of the Site, you warrant
          that you will not use the Site for any purpose that is unlawful or
          prohibited by these Terms or by any local, state, national or
          international law, rule or regulation. You may not use the Site in any
          manner that could damage, disable, overburden, or impair the Site or
          interfere with any other party's use and enjoyment of the Site
          including the transmittal or posting of any material that contains a
          computer virus or corrupted data. You may not obtain or attempt to
          obtain any materials or information through any means not
          intentionally made available or provided for through the Site.
        </p>
        <p>
          You will not modify, publish, transmit, reverse engineer, participate
          in the transfer or sale, create derivative works, or in any way
          exploit any of the content, in whole or in part, found on the Site.
          Microincome.com's content is not for resale. Your use of the Site does
          not entitle you to make any unauthorized use of any protected content,
          and in particular you will not delete or alter any proprietary rights
          or attribution notices in any content. You will use protected content
          solely for your personal use, and will make no other use of the
          content without the express written permission of Microincome.com and
          the copyright owner. You agree that you do not acquire any ownership
          rights in any protected content. We do not grant you any licenses,
          express or implied, to the intellectual property of Microincome.com,
          the Site, or our licensors and related parties except as expressly
          authorized by these Terms.
        </p>
        <p>
          This license may be revoked at any time for any violation of any of
          Terms.
        </p>
        <h2>Copyright and Intellectual Property</h2>
        <p>
          All content included as part of the Service, such as text, graphics,
          logos, images, as well as the compilation thereof, and any software
          used on the Site, is the property of Microincome.com, its suppliers,
          content contributors, and other respective owners (the "Content
          Owners") and protected by copyright and other laws that protect
          intellectual property and proprietary rights. You agree to observe and
          abide by all copyright and other proprietary notices, legends or other
          restrictions contained in any such content and will not make any
          changes thereto.
        </p>
        <p>
          Any use of the Content not expressly permitted by these Terms is a
          breach of these Terms and may violate copyright, trademark, and other
          laws and may incur severe penalties. Content and features are subject
          to change or termination without notice in the editorial discretion of
          the Site. All rights not expressly granted herein are reserved to the
          Content Owners. Using our Services does not give you ownership of any
          intellectual property rights in our Services or the content you
          access. You may not use content from our Services unless you obtain
          permission from its owner or are otherwise permitted by law. These
          terms do not grant you the right to use any branding or logos used in
          our Services. Don't remove, obscure, or alter any legal notices
          displayed in or along with our Services.
        </p>
        <p>
          We respond to notices of alleged copyright infringement and terminate
          accounts of repeat infringers according to the process set out in the
          U.S. Digital Millennium Copyright Act of 1998 (the "Act"). If you
          believe in good faith that materials on this Site infringe your
          copyright, you or your agent may send a notice requesting that the
          material be removed or access to it be blocked. Any notification by a
          copyright owner or a person authorized to act on its behalf that fails
          to comply with requirements of the Act shall not be considered
          sufficient notice. If you believe in good faith that a notice of
          copyright infringement has been wrongly filed against you, the Act
          permits you to send a counter-notice.
        </p>
        <h2>Dealings With Third Parties</h2>
        <p>
          This Site may contain links to other, third party websites ("Linked
          Sites"). The Linked Sites are not under the control of Microincome.com
          and Microincome.com is not responsible for the contents of any Linked
          Site, including without limitation any link contained in a Linked
          Site, or any changes or updates to a Linked Site. Microincome.com is
          providing these links to you only as a convenience, and the inclusion
          of any link does not imply endorsement by Microincome.com of the site
          or any association with its operators.
        </p>
        <p>
          If you choose to use the services provided by any third party
          websites, you may be asked by those sites to provide certain
          personally identifiable information. Microincome.com is not
          responsible for the privacy practices of those sites. Please be aware
          when you leave our site, and read the privacy policies of each and
          every website that you visit. This Agreement applies solely to this
          Website. Microincome.com is not responsible for the contents of any
          linked site or any link contained in a linked site.
        </p>
        <p>
          Certain services made available via this Site are delivered by third
          party sites and organizations. By using any product, service or
          functionality originating from this domain, you hereby acknowledge and
          consent that Microincome.com may share such information and data with
          any third party with whom Microincome.com has a contractual
          relationship to provide the requested product, service or
          functionality on behalf of this Site's users and customers.
        </p>
        <p>
          Any opinions, advice, statements, services, advertisements, offers or
          other information or content expressed or made available through the
          Sites by third parties, including information providers, are those of
          the respective authors or distributors and not Microincome.com. Third
          party content is the sole responsibility of the entity that makes it
          available
        </p>
        <p>
          Your participation, correspondence or business dealings with any third
          party found on or through our Site and Services, including, without
          limitation, advertisers and other users, regarding payment and
          delivery of specific goods and services, and any other terms,
          conditions, representations or warranties associated with such
          dealings, are solely between you and such third party. You agree that
          Microincome.com shall not be responsible or liable for any loss,
          damage, or other matters of any sort incurred as the result of such
          dealings.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to indemnify, defend and hold Microincome.com, its officers,
          directors, shareholders, employees, agents, affiliates, licensors,
          suppliers, managers, contractors, sponsors and third parties, from and
          against any and all claims, actions or demands, losses, costs,
          liabilities and settlements, and expenses (including reasonable
          attorneys' fees) relating to or arising out of your breach of this
          Agreement, any allegation of infringement of an intellectual property
          right, or your use of or inability to use the Site or Services, any
          user postings made by you, your violation of any terms of this
          Agreement or your violation of any rights of a third party, or your
          violation of any applicable laws, rules or regulations.
          Microincome.com reserves the right, at its own cost, to assume the
          exclusive defense and control of any matter otherwise subject to
          indemnification by you, in which event you will fully cooperate with
          Microincome.com in asserting any available defenses.
        </p>
        <h2>Disclaimer of Warranties</h2>
        <p>
          THIS SITE AND THE SERVICES, AND ANY CONTENT, TOOLS, PRODUCTS OR
          SERVICES DISPLAYED, ACCESSED OR OBTAINED ON OR THROUGH THE SITE AND
          SERVICES ARE PROVIDED "AS IS", "AS AVAILABLE", AND WITHOUT WARRANTIES
          OF ANY KIND, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT
          LIMITATION, WARRANTIES OF TITLE, NON-INFRINGEMENT, AVAILABILITY,
          TIMELINESS, MERCHANTABILITY OR SUITABILITY FOR A PARTICULAR PURPOSE.
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, MICROINCOME.COM, ITS
          LICENSORS AND THEIR AFFILIATES, SUPPLIERS, AND AGENTS DO NOT WARRANT
          AND EXPRESSLY DISCLAIM THAT: (i) YOUR USE OF THE SITE AND/OR SERVICES
          AND ACCESS TO AND USE OF ALL OF THE TOOLS AND FEATURES THEREON WILL BE
          UNINTERRUPTED, ERROR-FREE OR SECURE; (ii) THAT ANY INFORMATION
          OBTAINED THEREIN IS ACCURATE, RELIABLE OR COMPLETE; (iii) THAT DEFECTS
          WILL BE CORRECTED; OR (iv) THAT ANY SOFTWARE, SERVICES, SITES OR
          SERVER(S) ON WHICH THE SITES OR SERVICES ARE HOSTED ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS. YOUR USE OF THE SITE AND THE
          SERVICES AND ANY INFORMATION OR MATERIALS PROVIDED ON OR THROUGH THE
          SITE AND SERVICES ARE ENTIRELY AT YOUR OWN RISK. WE MAKE NO
          REPRESENTATIONS OR WARRANTIES ABOUT THE PRODUCTS OR SERVICES SOLD OR
          ADVERTISED HEREIN WITH REGARD TO THE CONTENT CONTAINED ON THE SITE OR
          THROUGH THE SERVICES. NOTHING ON THIS WEBSITE CONSTITUTES, OR IS MEANT
          TO CONSTITUTE, ADVICE OF ANY KIND.
        </p>
        <h2>Disclaimer And Limitation of Liabilities</h2>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          MICROINCOME.COM, AND/OR ITS OWNERS, DIRECTORS, OFFICERS, EMPLOYEES,
          MANAGERS, CONTRACTORS, SHAREHOLDERS, SUPPLIERS, AGENTS, LICENSORS OR
          SPONSORS BE LIABLE FOR ANY LOSS OR INJURY OR ANY DIRECT, INDIRECT,
          PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
          WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
          DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
          OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
          SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
          SERVICES, OR FOR ANY ERRORS OR OMISSIONS, OR FOR ANY INFORMATION,
          SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE
          SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED
          ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
          MICROINCOME.COM OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
          POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
          INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
          ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
          TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
          THE SITE.
        </p>
        <h2>Updates</h2>
        <p>
          Microincome.com reserves the right, in its sole discretion, to change
          the Terms under which this Site is offered without notice. The most
          current version of the Terms will supersede all previous versions. It
          is your responsibility to review the Terms to stay informed of our
          updates.
        </p>
        <p>
          Your continued access or use of the Site or Services following changes
          in the Terms signifies your acceptance of the modified terms. If you
          disagree with the modified terms, your sole recourse is to cease
          access to the Site and Services.
        </p>
        <h2>General Provisions</h2>
        <p>
          The Site and Service are controlled, operated and administered by
          Microincome.com from our offices within the USA. If you access the
          Service from a location outside the USA, you are responsible for
          compliance with all local laws. You agree that you will not use the
          Microincome.com content accessed through Microincome.com in any
          country or in any manner prohibited by any applicable laws,
          restrictions or regulations.
        </p>
        <p>
          We may perform any of our obligations or exercise any of our rights
          under these Terms through one or more of our company affiliates
          (including any entity that directly or indirectly controls, is
          controlled by or is under common control with us).
        </p>
        <p>
          Unless otherwise indicated elsewhere in this Agreement, (i) the term
          "or" shall not be exclusive; (ii) the term "including" shall mean
          "including, but not limited to," and (iii) the terms "herein,"
          "hereof," "hereto," "hereunder" and other terms similar to such terms
          shall refer to this Agreement as a whole and not merely to the
          specific section, subsection, paragraph or clause where such terms may
          appear. The captions, section, subsection and any paragraph headings
          contained herein are for purposes of convenience only and are not
          intended to define or limit or affect, and shall not be considered in
          connection with, the interpretation of any of the terms or provisions
          of this Agreement. Any reference in this Agreement to the singular
          includes the plural where appropriate. Any reference in this Agreement
          to the masculine gender includes the feminine and neuter genders where
          appropriate.
        </p>
        <p>
          If any part of this Agreement is determined to be invalid or
          unenforceable pursuant to applicable law including, but not limited
          to, the warranty disclaimers and liability limitations set forth
          above, then the invalid or unenforceable provision will be deemed
          superseded by a valid, enforceable provision that most closely matches
          the intent of the original provision and the remainder of the
          agreement shall continue in effect.
        </p>
        <p>
          Terms and conditions of this Agreement which by their sense and
          context survive the completion, termination, cancellation or
          expiration of this Agreement will so survive.
        </p>
        <p>
          To the extent that there is any inconsistency between this Agreement
          and the Privacy Policy, this Agreement shall govern.
        </p>
        <p>
          The failure of Microincome.com to exercise or enforce any right or
          provision of these Terms shall not constitute a waiver of such right
          or provision. If any provision of these Terms is found by a court of
          competent jurisdiction to be unlawful, void or for any reason
          unenforceable, then that provision shall be deemed severed herefrom
          and shall not affect the validity and enforceability of any remaining
          provisions.
        </p>
        <p>
          To the maximum extent permitted by law, this Agreement is governed by
          the laws of the State of California, County of Orange, and you hereby
          consent to the exclusive jurisdiction and venue of courts in
          California in all disputes arising out of or relating to the use of
          the Site. Use of the Site is unauthorized in any jurisdiction that
          does not give effect to all provisions of these Terms, including,
          without limitation, this section.
        </p>
        <p>
          Microincome.com may transfer, sub-contract or otherwise deal with your
          rights and/or obligations under these terms and conditions without
          notifying you or obtaining your consent.
        </p>
        <p>
          You may not transfer, sub-contract or otherwise deal with your rights
          and/or obligations under these terms and conditions.
        </p>
        <p>
          Unless otherwise specified herein, this agreement constitutes the
          entire agreement between the User and Microincome.com with respect to
          the Site and it supersedes all prior or contemporaneous communications
          and proposals, whether electronic, oral or written, between the User
          and Microincome.com with respect to the Site.
        </p>
        <p>
          Microincome.com welcomes your questions or comments regarding the
          Terms. You may contact us at:
        </p>
        <p>
          Microincome.com
          <br />
          4600 Campus Drive
          <br />
          Newport Beach, CA 92660
        </p>
      </Container>
      <Newsletter />
    </BackgroundInterior>
  </Layout>
)

export default TermsOfServicePage
